import { IconNames } from '@temperworks/types'

export default (root:any, be:string, routes:any) => {
  const freeflexerStickyNavbar = [
    {
      link: `${be}/${routes.contractor_jobs}`,
      icon: IconNames.search,
      linkText: root.t('freeflexer.navigation.find_work')
    },
    {
      link: be + '/dashboard-contractor',
      icon: IconNames.calendar,
      linkText: root.t('freeflexer.navigation.dashboard')
    },
    {
      link: routes.faq,
      icon: IconNames.speechBubble,
      linkText: root.t('freeflexer.navigation.help')
    },
    {
      link: be + '/dashboard/profile',
      icon: IconNames.person,
      linkText: root.t('freeflexer.navigation.profile')
    },
  ]
  return {
    freeflexerStickyNavbar
  }
}
