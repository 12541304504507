<script setup lang="ts">
import { ref } from 'vue'
import { AppLink } from '@temperworks/components'
import { TemperIcon } from '@temperworks/icons'
interface Props {
  open: boolean
  authenticated: boolean
  mobileDevice: boolean
}
const props = withDefaults(defineProps<Props>(), {
  open: false,
  authenticated: undefined,
  mobileDevice: undefined
})
const link = ref<string>('/')
</script>

<template>
  <div
    class="logo"
    :class="
      [
        { 'purple500': props.open },
        { 'loggedin': props.authenticated }
      ]
    "
  >
    <AppLink
      :to="link"
    >
      <TemperIcon
        name="logoTemper"
        :color="
          props.authenticated ?
            props.mobileDevice ? 'green800' : 'green500' :
            props.mobileDevice ? 'green500' : 'green900'

        "
      />
    </AppLink>
  </div>
</template>

<style lang="scss" scoped>
@use './LogoContainer.scss';
</style>
