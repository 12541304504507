<script setup lang="ts">
import { ref } from 'vue'
import {
  NavigationMobile,
  LogoContainer,
  TopNavigation,
  Button,
} from '@temperworks/components'

import {
  MenuItemsMobile,
  ButtonType
} from '@temperworks/types'
import { Flag } from '@temperworks/icons'

interface Props {
  menuItems: MenuItemsMobile[],
  mobileDevice: boolean,
  country: string
}
const props = defineProps<Props>()
const openMenu = ref<boolean>(false)
const emit = defineEmits(['country', 'signin', 'open', 'toggleSignupDropdown'])

function isOpened() {
  openMenu.value = !openMenu.value
  emit('open', openMenu.value)
}

function toggleSignupDropdown() {
  emit('toggleSignupDropdown')
}

function openCountry() {
  emit('country')
}

function signIn() {
  emit('signin')
}

</script>

<template>
  <div class="holder">
    <NavigationMobile
      v-if="props.mobileDevice"
      :mobile-menu-items="props.menuItems"
      @open="isOpened()"
    />
    <LogoContainer
      :authenticated="false"
      :open="openMenu"
      :mobile-device="props.mobileDevice"
    />
    <TopNavigation
      v-if="!props.mobileDevice"
      :menu-items="props.menuItems"
    />
    <div
      class="header-buttons"
      :class="{ 'hidden': openMenu }"
    >
      <div
        class="country-selector"
        @click="openCountry()"
      >
        <Flag :name="props.country" />
      </div>
      <Button
        class="button-auth--desktop"
        :variant="ButtonType.outlineHeader"
        :label="$t('header.signIn')"
        @click="signIn()"
      />
      <Button
        :variant="ButtonType.plainLarge"
        :label="$t('header.signUp')"
        @click="toggleSignupDropdown()"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use './UnauthenticatedMenu.scss';
</style>
