<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CountrySelector } from '@temperworks/components'
import { useCountryStore } from '../../store/country'
import countrySettings from '../../composables/countrySettings'
import languageSettings from '../../composables/languageSettings'

const text = useI18n()
const countryStore = useCountryStore()
const nuxtApp = useNuxtApp()
const { t } = useI18n()

const countryPicker = computed(() => {
  return {
    countryName: t('countrySelector.' + countryStore.country + '.country'),
    flag: countryStore.country
  }
})

function openCountrySelector() {
  countryStore.setCountryChange()
}

function changeCountry(country:any) {
  nuxtApp.$changeCountry(country, text)
  countryStore.setCountryChange()
}
</script>

<template>
  <CountrySelector
    :country="countryPicker"
    :language="t('countrySelector.'+ countryStore.country +'.language')"
    :country-items="countrySettings(text).countrySettings"
    :language-items="languageSettings(text).languageSettings"
    :open="countryStore.changeCountry"
    @close="openCountrySelector()"
    @changed="changeCountry($event)"
  />
</template>
