<script setup lang="ts">
import { ref } from 'vue'
import {
  NavigationItem,
} from '@temperworks/components'
import {
  MenuItemsMobile,
} from '@temperworks/types'
import { TemperIcon } from '@temperworks/icons'
interface Props {
  mobileMenuItems: MenuItemsMobile[],
}
const props = defineProps<Props>()
const emit = defineEmits(['open'])
const openNav = ref<boolean>(false)
function openMenu() {
  openNav.value = !openNav.value
  emit('open', openNav.value)
}
</script>

<template>
  <div
    class="menu-icon"
    @click="openMenu()"
  >
    <TemperIcon
      :name="openNav ? 'arrowLeft' : 'menu'"
      :fill="openNav ? 'dark' : 'bright'"
    />
  </div>
  <div
    class="nav-mobile"
    :class="{ 'active': openNav}"
  >
    <nav class="main-nav">
      <ul>
        <NavigationItem
          v-for="(item, index) in props.mobileMenuItems"
          :key="index"
          :permission="item.permission"
          :link="item.link"
          :link-text="item.linktext"
        />
      </ul>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
@use './NavigationMobile.scss';
</style>
