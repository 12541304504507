<script setup lang="ts">
import { ref } from 'vue'
import { TemperIcon } from '@temperworks/icons'
import { IconNames } from '@temperworks/types'
import { DropDownMenu } from '@temperworks/components'
interface Props {
  items: Array<any>
}
const props = defineProps<Props>()
const openQA = ref<boolean>(false)
function openQAMenu() {
  openQA.value = !openQA.value
}
</script>

<template>
  <div
    class="menu-icon"
    @click="openQAMenu()"
  >
    <TemperIcon
      :name="IconNames.more"
      fill="dark"
    />
  </div>
  <div
    class="nav-mobile user-link quick-actions"
    :class="{ 'active': openQA }"
  >
    <DropDownMenu
      :items="props.items"
      :quick-actions="true"
    />
  </div>
</template>

<style lang="scss" scoped>
@use './NavigationMobileAuth.scss';
</style>
