import { FetchApi } from '@temperworks/types'
import { useAuthStore } from '../store/auth'
import { computed, inject, ref, watch } from 'vue'

interface MigrationStatusRes {
  'has-invoices-temper-web': boolean
  'has-invoices-external-service': boolean
}

interface MigrationNavigationData {
  type: 'internal' | 'external'
  link: string
}

const COOKIE_NAME = 'invoiceingMigrationStatus'

/**
 * temorarly solution to handle the migration to pfp platform
 * used to handle the navigation to the finance overview page
 */
export const useMigrationStatus = () => {
  const auth = useAuthStore()
  const store = useCountryStore()

  const fetchApi = inject('fetchApi') as FetchApi
  const isLoading = ref(false)

  const migrationStatus = ref<MigrationStatusRes>({
    'has-invoices-temper-web': false,
    'has-invoices-external-service': false,
  })

  /**
    * Store the migration status in cookies for the one day
    */
  const storeMigrationStatus = async (migrationStatusRes: MigrationStatusRes) => {
    document.cookie = `${COOKIE_NAME}=${JSON.stringify(migrationStatusRes)};max-age=86400;path=/`
  }

  const getCookieMigrationStatus = (): MigrationStatusRes | null => {
    const cookies = document.cookie.split(';')
    const cookie = cookies.find(c => c.includes(COOKIE_NAME))
    if (!cookie) {
      return null
    }

    return JSON.parse(cookie.split('=')[1])
  }

  const fetchAlertStatus = async () => {
    const urlPath = '/api/v1/invoices/migration-status'

    isLoading.value = true
    try {
      const res = await fetchApi(urlPath, store.backend, {
        method: 'GET',
      })
      const data = (await res.json()) as MigrationStatusRes

      storeMigrationStatus(data)
      migrationStatus.value = data
    } catch (e) {
      console.error('Failed to fetch migration status', e)
      // we fallback to true because it's better to see an empty legacy
      // page than not seeing invoices at all
      migrationStatus.value = {
        'has-invoices-temper-web': true, // legacy invoices from TW
        'has-invoices-external-service': true, // invocies from invocing-service
      }
    } finally {
      isLoading.value = false
    }
  }

  watch(
    [store.backend, auth.token],
    () => {
      if (store.backend && auth.token && !isLoading.value) {
        const cookieMigrationStatus = getCookieMigrationStatus()
        if (cookieMigrationStatus) {
          migrationStatus.value = cookieMigrationStatus
        } else {
          fetchAlertStatus()
        }
      }
    },
    { immediate: true },
  )

  const freeflexerFinOverviewNavigation = computed<MigrationNavigationData>(() => {
    const freeflexerUrlPath = '/dashboard/contractor/invoices'

    return getFinOverviewPath({
      countryName: store.country,
      legacyBaseUrl: store.backend,
      finOverviewPath: freeflexerUrlPath,
      migrationStatus: migrationStatus.value,
    })
  })

  const clientFinOverviewNavigation = computed<MigrationNavigationData>(() => {
    const clientUrlPath = '/dashboard/client/invoices'

    return getFinOverviewPath({
      countryName: store.country,
      legacyBaseUrl: store.backend,
      finOverviewPath: clientUrlPath,
      migrationStatus: migrationStatus.value,
    })
  })

  return {
    migrationStatus,
    freeflexerFinOverviewNavigation,
    clientFinOverviewNavigation,
  }
}

type GetFinOverviewPathArgs = {
  countryName: string // 'uk' | 'nl' | 'fr'
  legacyBaseUrl: string
  finOverviewPath: string
  migrationStatus: MigrationStatusRes
}

const getFinOverviewPath = ({
  countryName, legacyBaseUrl, finOverviewPath, migrationStatus
}: GetFinOverviewPathArgs): MigrationNavigationData => {
  // FR should always be internal
  if (countryName === 'fr') {
    return {
      type: 'internal',
      link: finOverviewPath,
    }
  }

  // UK and NL is depend on the flag
  if (migrationStatus['has-invoices-external-service']) {
    return {
      type: 'internal',
      link: finOverviewPath,
    }
  }

  return {
    type: 'external',
    link: legacyBaseUrl + finOverviewPath,
  }
}
