<script setup lang="ts">
import { ref } from 'vue'
import { DropDownMenu, CompleteProfilePrompt } from '@temperworks/components'
import { TemperIcon } from '@temperworks/icons'
interface Props {
  items: Array<any>
  badge?: string | number
  showCompleteProfile?: boolean,
  completeProfilePercentage?: number
}
const props = defineProps<Props>()

const emit = defineEmits(['open', 'logout'])
const openNav = ref<boolean>(false)
function openMenu() {
  openNav.value = !openNav.value
  emit('open', openNav.value)
}
</script>

<template>
  <div
    class="menu-icon"
    @click="openMenu()"
  >
    <TemperIcon
      :name="openNav ? 'arrowLeft' : badge ? 'menuUnread' : 'menu'"
      fill="dark"
    />
  </div>
  <div
    class="nav-mobile user-link"
    :class="{ 'active': openNav }"
  >
    <CompleteProfilePrompt
      v-if="props.showCompleteProfile"
      :mobile-device="true"
      :percentage="props.completeProfilePercentage"
    />
    <DropDownMenu
      :items="props.items"
      @logout="emit('logout')"
    />
  </div>
</template>

<style lang="scss" scoped>
@use './NavigationMobileAuth.scss';
</style>
