<script setup lang="ts">
import { ref } from 'vue'
import {
  NavigationMobileAuth,
  LogoContainer,
  TopNavigation,
  UserMenu,
  Typography,
  AdminMenu,
  CompleteProfilePrompt
} from '@temperworks/components'

import {
  MenuItemsMobile,
  TypographyType,
  User,
} from '@temperworks/types'

import QuickActionsMenu from './QuickActionsMenu.vue'
interface Props {
  user: User,
  menuItems: MenuItemsMobile[],
  menuItemsMobile?: Array<any>,
  menuItemsDropdown: Array<any>,
  mobileDevice: boolean,
  isAdmin?: boolean,
  isImpersonating?: boolean,
  adminMenu?: Array<any>,
  badge?: string | number,
  qaMenu?: Array<any>,
  showCompleteProfile?: boolean,
  completeProfilePercentage?: number,
}
const props = defineProps<Props>()
const emit = defineEmits(['logout', 'stopImpersonation'])
const openMenu = ref<boolean>(false)
const openAdminMenu = ref<boolean>(false)
function isOpened() {
  openMenu.value = !openMenu.value
}
function openUserAdmin() {
  openAdminMenu.value = !openAdminMenu.value
}
function stopImpersonation() {
  emit('stopImpersonation')
}
</script>

<template>
  <div class="holder">
    <NavigationMobileAuth
      v-if="mobileDevice"
      :items="props.menuItemsMobile ? props.menuItemsMobile : props.menuItems"
      :badge="props.badge"
      :complete-profile-percentage="props.completeProfilePercentage"
      :show-complete-profile="props.showCompleteProfile"
      @open="isOpened()"
      @logout="emit('logout')"
    />
    <LogoContainer
      :authenticated="true"
      :open="openMenu"
      :mobile-device="props.mobileDevice"
    />
    <div class="nav-top">
      <TopNavigation
        v-if="!mobileDevice"
        :menu-items="props.menuItems"
      />
    </div>
    <CompleteProfilePrompt
      v-if="props.showCompleteProfile && !props.mobileDevice"
      :mobile-device="false"
      :percentage="props.completeProfilePercentage"
    />
    <div
      v-if="props.isAdmin && props.isImpersonating && !mobileDevice"
      class="user-menu admin"
      @click="openUserAdmin()"
    >
      <AdminMenu
        v-if="props.adminMenu"
        :menu-items="props.adminMenu"
      />
    </div>
    <div
      class="dropdown-menu-item"
    >
      <UserMenu
        :menu-items-dropdown="props.menuItemsDropdown"
        :menu-items-dropdown-mobile="props.menuItemsDropdown"
        :mobile-device="props.mobileDevice"
        :is-impersonating="props.isImpersonating"
        :user="props.user"
        @logout="emit('logout')"
      />
    </div>
    <div
      v-if="mobileDevice && props.qaMenu"
    >
      <QuickActionsMenu
        :items="props.qaMenu"
      />
    </div>
    <div
      v-if="props.isImpersonating"
      class="stop-impersonation"
      @click="stopImpersonation()"
    >
      <Typography
        :variant="TypographyType.bodyMedium"
        :content="$t('header.auth.si')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use './AuthenticatedMenu.scss';
</style>
