<script setup lang="ts">
import { NavigationItem } from '@temperworks/components'
interface Props {
  adminMenu?: Array<any>
}
const props = defineProps<Props>()
</script>

<template>
  <div class="user-menu-open">
    <ul>
      <span
        v-for="item in props.adminMenu"
        :key="item.id"
      >
        <NavigationItem
          :permission="item.permission"
          :link="item.link"
          :link-text="item.linktext"
          class="user-link"
        />
      </span>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@use './DropDownMenu.scss';
</style>
