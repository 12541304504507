<script setup lang="ts">
import { ref } from 'vue'
import { TemperIcon } from '@temperworks/icons'
import { Typography, DropDownMenu, DimmingOverlay } from '@temperworks/components'
import { TypographyType, User, IconNames, IconColors, IconPosition } from '@temperworks/types'

interface Props {
  user: User,
  menuItemsDropdown: Array<any>,
  menuItemsDropdownMobile: Array<any>,
  mobileDevice: boolean,
  isImpersonating: boolean,
}
const props = defineProps<Props>()
const emit = defineEmits(['logout'])
const dropdownMenuOpen = ref<boolean>(false)
function openDropdown() {
  dropdownMenuOpen.value = !dropdownMenuOpen.value
}
</script>

<template>
  <div
    v-if="!props.mobileDevice"
    class="user-menu justify-end"
    :class="{ 'opened': dropdownMenuOpen}"
    @click="openDropdown()"
  >
    <div
      v-if="props.isImpersonating"
      class="impersonation"
    >
      <TemperIcon
        :name="IconNames.impersonation"
        :fill="IconColors.bright"
      />
    </div>
    <TemperIcon
      v-else
      :name="IconNames.person"
      :fill="IconColors.bright"
      :position="IconPosition.left"
    />
    <div
      v-if="props.user"
      class="user-name-holder"
    >
      <Typography
        :variant="TypographyType.body"
        :content="$t('header.auth.hi') + ' ' + props.user.first_name"
      />
    </div>
    <TemperIcon
      :name="props.mobileDevice ? IconNames.more : IconNames.chevronDown"
      size="standard"
      :fill="props.mobileDevice ? IconColors.dark : IconColors.bright"
      class="arrow"
    />
    <DropDownMenu
      v-if="!props.mobileDevice && dropdownMenuOpen"
      :open="dropdownMenuOpen"
      :items="props.menuItemsDropdown"
      :mobile-device="props.mobileDevice"
      @logout="emit('logout')"
    />
    <DropDownMenu
      v-else-if="dropdownMenuOpen"
      :open="dropdownMenuOpen"
      :items="props.menuItemsDropdownMobile"
      :mobile-device="props.mobileDevice"
      @logout="emit('logout')"
    />
  </div>
  <DimmingOverlay
    v-if="dropdownMenuOpen"
    variant="bright"
    @close="openDropdown()"
  />
</template>

<style lang="scss" scoped>
@use './UserMenu.scss';
</style>
