<script setup lang="ts">
import {
  Typography,
  NavigationItem
} from '@temperworks/components'

import {
  TypographyType
} from '@temperworks/types'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
interface Props {
  items: Array<any>
  quickActions?: boolean
  mobileDevice?: boolean
}
const props = defineProps<Props>()
const emit = defineEmits(['logout'])
function handleLogout() {
  emit('logout')
}
</script>

<template>
  <div
    :class="{ 'user-menu-open' : !mobileDevice }"
  >
    <div class="user-menu-link">
      <span
        v-for="item in items"
        :key="item.id"
      >
        <Typography
          v-if="item.type === 'title'"
          :variant="TypographyType.caption"
          :content="item.content"
          class="caption grey600"
        />
        <NavigationItem
          v-else
          :link="item.link"
          :link-text="item.linktext"
          :badge="item.badge"
          :permission="item.permission"
          :icon="item.icon"
          :class="item.class"
        />
      </span>
    </div>
    <div
      v-if="!props.quickActions"
      class="logout"
      @click="handleLogout()"
    >
      <Typography
        :variant="TypographyType.body"
        :content="t('header.clients.signout')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use './DropDownMenu.scss';
</style>
