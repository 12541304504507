<script setup lang="ts">
import { NavigationItem } from '@temperworks/components'
import { useRoute } from 'vue-router'
interface Props {
  menuItems: Array<any>
}
const props = defineProps<Props>()
const route = useRoute()

</script>

<template>
  <nav class="main-nav">
    <ul>
      <NavigationItem
        v-for="item in props.menuItems"
        :active="item.name === route.name"
        :key="item.id"
        :permission="item.permission"
        :link="item.link"
        :link-text="item.linktext"
        :badge="item.badge"
        :icon="item.icon"
      />
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
@use './TopNavigation.scss'
</style>
