<script setup lang="ts">
import { Typography, Badge, AppLink } from '@temperworks/components'
import { TypographyType, BadgeVariants } from '@temperworks/types'
import { TemperIcon } from '@temperworks/icons'

interface Props {
  link: string
  linkText: string
  badge?: number
  permission: boolean
  icon?: string
  active?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  permission: false,
  badge: undefined,
  icon: undefined,
  active: false
})
</script>

<template>
  <li v-if="props.permission" :class="{ active }">
    <AppLink :to="props.link">
      <TemperIcon
        v-if="props.icon"
        :name="props.icon"
        position="left"
      />
      <Typography
        :variant="TypographyType.bodyMedium"
        :content="linkText"
      />
      <Badge
        v-if="props.badge"
        :badge="props.badge"
        :variant="BadgeVariants.header"
      />
    </AppLink>
  </li>
</template>

<style lang="scss" scoped>
@use './NavigationItem.scss';
</style>
