<script setup lang="ts">
import { ref } from 'vue'
import { UnAuthenticatedMenu, AuthenticatedMenu, SignupDropdown } from '@temperworks/components'
import { MenuItemsMobile, User } from '@temperworks/types'

interface Props {
  authenticatedUser: boolean,
  menuItems: MenuItemsMobile[],
  menuItemsDropdown: Array<any>,
  menuItemsMobile?: Array<any>,
  mobileDevice: boolean,
  country: string,
  user?: User,
  isAdmin?: boolean,
  isImpersonating?:boolean,
  adminMenu?: Array<any>,
  badge?: number | string,
  qaMenu?: Array<any>,
  showCompleteProfile?: boolean,
  completeProfilePercentage?: number,
}
const props = defineProps<Props>()
const emit = defineEmits(['logout', 'stopImpersonation', 'country', 'signin', 'signup'])
const openMobileMenu = ref<boolean>(false)
const openSignupDropdown = ref<boolean>(false)
function mobileMenu(truth:boolean) {
  openMobileMenu.value = truth
}
function toggleSignupDropdown() {
  openSignupDropdown.value = !openSignupDropdown.value
}
function signUp(event: any) {
  toggleSignupDropdown()
  emit('signup', event)
}
function signIn() {
  toggleSignupDropdown()
  emit('signin')
}
</script>

<template>
  <header>
    <div
      class="inner"
      :class="
        [
          {'authenticated': props.authenticatedUser},
          {'open': openMobileMenu }
        ]
      "
    >
      <UnAuthenticatedMenu
        v-if="!props.authenticatedUser"
        :menu-items="props.menuItems"
        :mobile-device="props.mobileDevice"
        :country="props.country"
        @signin="emit('signin')"
        @country="emit('country')"
        @open="mobileMenu($event)"
        @toggle-signup-dropdown="toggleSignupDropdown()"
      />
      <AuthenticatedMenu
        v-else
        :menu-items="props.menuItems"
        :menu-items-dropdown="props.menuItemsDropdown"
        :menu-items-mobile="props.menuItemsMobile"
        :mobile-device="props.mobileDevice"
        :user="props.user"
        :is-admin="props.isAdmin"
        :is-impersonating="props.isImpersonating"
        :admin-menu="props.adminMenu"
        :badge="props.badge"
        :qa-menu="props.qaMenu"
        :show-complete-profile="props.showCompleteProfile"
        :complete-profile-percentage="props.completeProfilePercentage"
        @logout="emit('logout')"
        @stop-impersonation="emit('stopImpersonation')"
      />
      <SignupDropdown
        :open="openSignupDropdown"
        @signup="signUp($event)"
        @signin="signIn()"
        @toggle-signup-dropdown="toggleSignupDropdown()"
      />
    </div>
  </header>
</template>

<style lang="scss" scoped>
@use './HeaderContainer.scss';
</style>
