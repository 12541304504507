<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { HeaderContainer } from '@temperworks/components'
import { useCountryStore } from '../../store/country'
import { useUserStore } from '../../store/user'
import { useSettingsStore } from '../../store/settings'
import { useClientStore } from '../../store/client'
import unauthenticatedMenu from '../../composables/navigationUnAuth'
import clientTopNavigation from '../../composables/navigationAuth'
import clientDropdown from '../../composables/clientDropdown'
import clientMenuMobile from '../../composables/navigationAuthMobile'
import quickActionsClient from '../../composables/quickActionMenu'
import { useMigrationStatus } from '../../composables/useMigrationStatus'

const text = useI18n()
const countryStore = useCountryStore()
const userStore = useUserStore()
const settingsStore = useSettingsStore()
const clientStore = useClientStore()
const navigate = inject('navigate')
const route = useRoute()

const { clientFinOverviewNavigation } = useMigrationStatus()

function openCountrySelector () {
  countryStore.setCountryChange()
}

function logout () {
  navigate('/logout')
}

function signin () {
  navigate(countryStore.backend + '/login/identify/')
}

function stopImpersonation () {
  userStore.setImpersonation(false, null)
}

/**
 * main menu items
 */
const menuItems = computed(() => {
  if (!userStore.isUserLoggedIn) {
    return unauthenticatedMenu(text).unauthenticatedMenu
  }

  return clientTopNavigation(
    text,
    userStore.userPermissions,
    countryStore.backend,
    clientStore.onboardingCompleted,
    clientStore.openCheckouts
  ).clientTopNavigation
})

/**
 * dropdown menu items
 */
const menuItemsDropdown = computed(() => {
  if (!userStore.isUserLoggedIn) {
    return []
  }

  return clientDropdown(
    text,
    userStore.userPermissions,
    countryStore.backend,
    countryStore.country,
    countryStore.features,
    clientFinOverviewNavigation.value
  ).clientDropDown
})

/**
 * mobile menu items
 */
const menuItemsMobile = computed(() => {
  if (!userStore.isUserLoggedIn) {
    return []
  }

  return clientMenuMobile(
    text,
    userStore.userPermissions,
    countryStore.backend,
    countryStore.country,
    undefined,
    countryStore.features
  ).clientMenuMobile
})

/**
 * quick actions menu items
 */
const qaMenu = computed(() => {
  if (!userStore.isUserLoggedIn) {
    return []
  }

  return quickActionsClient(
    route.name,
    text,
    userStore.userPermissions,
    countryStore.backend
  ).quickActionsClient
})

</script>

<template>
  <HeaderContainer
    :authenticated-user="Boolean(userStore.loggedInUser)"
    :menu-items="menuItems"
    :menu-items-dropdown="menuItemsDropdown"
    :menu-items-mobile="menuItemsMobile"
    :mobile-device="settingsStore.isTablet"
    :country="countryStore.country"
    :user="userStore.user"
    :qa-menu="qaMenu"
    :is-impersonating="userStore.isImpersonating"
    :is-admin="userStore.temperAdmin"
    @signin="signin()"
    @stop-impersonation="stopImpersonation()"
    @country="openCountrySelector()"
    @logout="logout()"
  />
</template>
