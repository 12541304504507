<script setup lang="ts">
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  Modal,
  Dropdown,
  DimmingOverlay,
  ButtonGroup,
} from '@temperworks/components'

import {
  DropdownVariant,
  ButtonType,
  DimmingOverlayVariant,
  CountrySettings,
  LanguageSettings,
  ModalVariant,
  ButtonGroupVariant,
  Country,
} from '@temperworks/types'

interface Props {
  country: Country
  language: string
  countryItems: CountrySettings
  languageItems: LanguageSettings
  open: boolean
}
const props = defineProps<Props>()
const newLang = ref<any>(undefined)
const { t } = useI18n()
const newCountry = ref<any>(undefined)
const emit = defineEmits(['setCountry', 'setLanguage', 'changed', 'close'])
const backButton = ref<any>({
  variant: ButtonType.textButtonLarge,
  label: t('controls.cancel')
})
const nextButton = ref<any>({
  variant: ButtonType.greenLarge,
  label: t('controls.save')
})

const availableLanguages = computed(() => {
  const activeCountry = newCountry.value?.country || props.country.flag
  const selectedLang = []
  for (let x = 0; x < props.languageItems.items.length; x++) {
    if (props.languageItems.items[x].forCountries.includes(activeCountry)) {
      selectedLang.push(props.languageItems.items[x])
    }
  }
  return selectedLang
})

function setCountry(country) {
  newCountry.value = country
  emit('setCountry', country)
}
function setLanguage(language) {
  newLang.value = language
  emit('setLanguage', language)
}

function submitChange() {
  emit('changed', {
    language: newLang.value ? newLang : undefined,
    country: newCountry.value ? newCountry : undefined
  })
}

function close() {
  emit('close')
}

</script>

<template>
  <Modal
    :variant="ModalVariant.dialog"
    :open="props.open"
    :modal-header="t('countrySelector.title')"
    :fluid="true"
    :scroll="false"
    @close="close()"
  >
    <template #body-content>
      <Dropdown
        :variant="DropdownVariant.default"
        :items="props.countryItems.items"
        :flag="newCountry ? newCountry.flag : props.country.flag"
        :selected="newCountry ? newCountry.name : props.country.countryName"
        :top-label="props.countryItems.topLabel"
        :placeholder="props.countryItems.topLabel"
        class="country-dropdown"
        @selected="setCountry($event)"
      />
      <Dropdown
        :variant="DropdownVariant.default"
        :items="availableLanguages"
        :selected="newLang ? newLang.name : newCountry ? availableLanguages[0].name : props.language"
        :top-label="props.languageItems.topLabel"
        :placeholder="props.languageItems.topLabel"
        class="country-dropdown"
        @selected="setLanguage($event)"
      />
    </template>
    <template #footer>
      <ButtonGroup
        :variant="ButtonGroupVariant.left"
        :back-button="backButton"
        :next-button="nextButton"
        @back="close()"
        @next="submitChange()"
      />
    </template>
  </Modal>
  <DimmingOverlay
    v-if="props.open"
    :variant="DimmingOverlayVariant.standard"
    @close="close()"
  />
</template>

<style lang="scss" scoped>
@use 'pkg:@temperworks/styles/sizes' as sizes;
.country-dropdown {
  margin-bottom: sizes.$double;
}
</style>
