<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import { inject } from 'vue'
import { TemperIcon } from '@temperworks/icons'
import {
  ProgressCircle,
  Typography,
} from '@temperworks/components'
import {
  ProgressCircleVariant,
  TypographyType,
  IconNames,
  IconColors
} from '@temperworks/types'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const navigate = inject('navigate') as Function

interface Props {
  percentage: number,
  mobileDevice: boolean
}
const props = defineProps<Props>()

function navigateToPage() {
  navigate('/freeflexer/complete-profile')
}
</script>

<template>
  <div
    class="completeProfilePrompt"
    :class="[
      {'mobile': props.mobileDevice},
      {'desktop': !props.mobileDevice}
    ]"
    @click="navigateToPage()"
  >
    <ProgressCircle
      class="completeProfilePrompt__circle"
      :variant="props.mobileDevice ? ProgressCircleVariant.primarySmall : ProgressCircleVariant.secondarySmall"
      :percentage="props.percentage"
    >
      <TemperIcon
        class="completeProfilePrompt__icon"
        :name="IconNames.profile"
        :color="props.mobileDevice ? IconColors.purple100 : IconColors.green800"
      />
    </ProgressCircle>
    <div class="completeProfilePrompt__text">
      <Typography
        :variant="props.mobileDevice ? TypographyType.bodyMedium : TypographyType.bodySmallMedium"
        :content="props.percentage < 100 ? t('header.completeProfile') : t('header.profileCompleted')"
      />
      <Typography
        v-if="props.mobileDevice && props.percentage < 100"
        :variant="TypographyType.footnote"
        :content="t('header.completeProfileText')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "./CompleteProfilePrompt.scss"
</style>
