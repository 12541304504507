<script setup lang="ts">
import { TemperIcon } from '@temperworks/icons'
import {
  AppLink,
  Button,
  DimmingOverlay,
  Typography,
} from '@temperworks/components'

import {
  ButtonType,
  DimmingOverlayVariant,
  TypographyType
} from '@temperworks/types'

interface Props {
  open?: boolean,
}

const props = defineProps<Props>()
const emit = defineEmits(['toggleSignupDropdown', 'signin', 'signup'])

function close() {
  emit('toggleSignupDropdown')
}
</script>

<template>
  <div
    class="header-dropdown"
    v-if="props.open"
  >
    <div class="header-dropdown__top">
      <Button
        :variant="ButtonType.plainLarge"
        :label="$t('controls.close')"
        @click="close()"
        class="header-dropdown__close-button"
      />
    </div>
    <nav class="header-dropdown__body">
      <div class="header-dropdown__body--inner">
        <div class="header-dropdown__mobile-login">
          <AppLink
            @click="emit('signin')"
            class="header-dropdown__button button--outline"
          >
            <div class="header-dropdown__button--inner">
              {{ $t('header.signIn') }}
            </div>
            <TemperIcon
              name="arrowRight"
              position="right"
              fill="bright"
            />
          </AppLink>
          <Typography
            :variant="TypographyType.bodySmallMedium"
            :content="$t('header.join')"
          />
        </div>
        <div class="header-dropdown__buttons">
          <AppLink
            @click="emit('signup', { type: 'job' })"
            class="header-dropdown__button button--white"
          >
            <TemperIcon
              name="person"
              position="left"
            />
            <div class="header-dropdown__button--inner">
              {{ $t('header.signUpJob.title') }}
              <span>
                {{ $t('header.signUpJob.caption') }}
              </span>
            </div>
            <TemperIcon
              name="arrowRight"
              position="right"
            />
          </AppLink>
          <AppLink
            @click="emit('signup', { type: 'client' })"
            class="header-dropdown__button button--green"
          >
            <TemperIcon
              name="company"
              position="left"
            />
            <div class="header-dropdown__button--inner">
              {{ $t('header.signUpClient.title') }}
              <span>
                {{ $t('header.signUpClient.caption') }}
              </span>
            </div>
            <TemperIcon
              name="arrowRight"
              position="right"
            />
          </AppLink>
        </div>
      </div>
    </nav>
  </div>
  <DimmingOverlay
    v-if="props.open"
    :variant="DimmingOverlayVariant.standard"
    @close="close()"
  />
</template>

<style lang="scss">
@use './SignupDropdown.scss';
</style>
