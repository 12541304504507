<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import { inject } from 'vue'
import { TemperIcon } from '@temperworks/icons'
import { Typography } from '@temperworks/components'
import {
  TypographyType,
  StickyNavbarItem,
  IconPosition
} from '@temperworks/types'

interface Props {
 items: Array<StickyNavbarItem>
}
const props = defineProps<Props>()
const navigate: Function = inject('navigate')
</script>

<template>
  <ul class="sticky-navbar">
    <li
      v-for="(item, index) in props.items"
      :key="`stickyNavbar-${index}`"
      class="sticky-navbar__item"
      @click="navigate(item.link)"
    >
      <TemperIcon
        class="sticky-navbar__icon"
        :name="item.icon"
        :position="IconPosition.center"
      />
      <Typography
        :variant="TypographyType.footnote"
        :content="item.linkText"
      />
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@use './StickyNavbar.scss';
</style>
